/*
    封装axios
    设置拦截器
    设置默认地址
*/
import axios from "axios";
import qs from "qs";
import local from "@/utils/local"
import router from "@/router";

//引入ui框架
import {
    Message,
    Loading
} from "element-ui";
import {
    config
} from "rxjs";


//设置默认地址
// 192.168.1.4
// 47.108.228.93

axios.defaults.baseURL = "http://47.108.228.93:8080"
//axios.defaults.baseURL = "http://127.0.0.1:8080"

// axios.defaults.baseURL = "http://127.0.0.1:8080"
//  axios.defaults.baseURL = "http://47.108.228.93:8080"
//axios.defaults.baseURL = "http://127.0.0.1:8080"
//let isShowLoading=true
axios.defaults.headers.post["Content-Type"] = "application/json";
// var getListLoading 
let isShowLoading = true

export default {
    //封装get请求
    changeShowLoadingT(){
        isShowLoading=true
    },
    changeShowLoadingF(){
     isShowLoading=false
    },
    
    get(url, params) {
        console.log(isShowLoading)
        let getListLoading 

        // if (isShowLoading) {
        //         getListLoading = Loading.service({
        //         lock: true,
        //         text: "加载中...",
        //         spinner: "el-icon-loading",
        //         background: "rgba(0, 0, 0, 0.7)",
        //         target: '.layout',
        //     });
        // }



        // const getListLoading = Loading.service({
        //     lock: true,
        //     text: "加载中...",
        //     spinner: "el-icon-loading",
        //     background: "rgba(0, 0, 0, 0.7)",
        //     target: '.layout',  
        //   });
        console.log(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params
            }).then((res) => {
                if(getListLoading){
                    getListLoading.close()
                }
                
                resolve(res.data);
            }).catch((erro) => {
                if(getListLoading){
                    getListLoading.close()
                }
                reject(erro);
            })
        })
    },
    // 封装post请求
    post(url, params) {
      
        console.log(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.post(url, qs.stringify(params)).then((res) => {
                resolve(res.data);
            }).catch((erro) => {
                reject(erro);
            })
        })
    },
    postlg(url, params) {
       // debugger
        console.log(isShowLoading)
        let getListLoading 

        // if (isShowLoading) {
        //     getListLoading = Loading.service({
        //         lock: true,
        //         text: "加载中...",
        //         spinner: "el-icon-loading",
        //         background: "rgba(0, 0, 0, 0.7)",
        //         target: '.layout',
        //     });
        // }

        // const getListLoading = Loading.service({
        //     lock: true,
        //     text: "加载中...",
        //     spinner: "el-icon-loading",
        //     background: "rgba(0, 0, 0, 0.7)",
        //     target: '.layout',  
        //   });
        console.log(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.post(url, params).then((res) => {
              
                if(getListLoading){
                    getListLoading.close()
                }
                resolve(res.data);
            }).catch((erro) => {
                if(getListLoading){
                    getListLoading.close()
                }
                reject(erro);
            })
        })
    },
}

// 设置请求拦截器
axios.interceptors.request.use((config) => {
    //在本地取出token
    let token = local.get('tk');
    //如何token存在，就设置在请求头中
    if (token) {
        config.headers.token = token;
    }
    return config
})

// //设置响应拦截
axios.interceptors.response.use((config) => {
    let {
        code,
        msg
    } = config.data;

    //如果code和msg不存在说明访问的是列表数据
    if (code !== undefined && msg !== undefined) {
        if (code == 0) {
            // Message({
            //     type: 'success',
            //     message: msg
            // })
        } else if (code == '00' || code == '11' || code == '2000') {
            //不做操作
        }
        else if (code == 401 || code == 600) {
            //跳转至登陆页面

            router.push('/login')
            //清空数据
            local.clear()
        }
        else {
            Message.error(msg)
        }
    }
    return config
}, (res) => {

    //如果响应后拿到的code为401，说明是无效的token 退出登录
    if (res.response.data.code == 401) {
        //跳转至登陆页面
        router.push('/login')
        //清空数据
        local.clear()
    }
})